import { useEffect } from 'react';
import { useGetPairsMutation } from 'services/admin';

interface Rate {
  id: string;
  pair: string;
  rate: string;
  markup: string;
  spread: number;
  updated_at: string;
}

const HeaderCell = ({ label }: { label: string }) => (
  <div className="w-[100px]">
    <p>{label}</p>
  </div>
);

const DataCell = ({ value }: { value: string | number }) => (
  <div
    style={{
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}
  >
    <p>{value}</p>
  </div>
);

const Divider = () => <div className="border-r-[2px] border-r-[#1174ED]"></div>;

const Pairs = () => {
  const [getRate, { data }] = useGetPairsMutation();

  useEffect(() => {
    getRate({});
  }, [getRate]);

  return (
    <div className="flex flex-col gap-4 lg:w-[50%] w-full">
      <div className="shadow-lg flex flex-col w-[100%] gap-4 bg-[#fff] xl:p-[20px] p-[10px] rounded-[10px]">
        <h1 className="text-xl font-bold underline">Rates</h1>
        <div className="flex justify-between font-bold gap-4">
          <HeaderCell label="Pairs" />
          <Divider />
          <HeaderCell label="Rates" />
          <Divider />
          <HeaderCell label="Markup" />
          <Divider />
          <HeaderCell label="Spread" />
        </div>

        {data?.data.map((rate: Rate) => (
          <div key={rate.id} className="flex justify-between gap-4">
            <DataCell value={rate.pair} />
            <Divider />
            <DataCell value={parseFloat(rate.rate).toFixed(2)} />
            <Divider />
            <DataCell value={parseFloat(rate.markup).toFixed(2)} />
            <Divider />
            <DataCell value={rate.spread} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pairs;
