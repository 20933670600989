import { createElement } from 'react';
import {
  ADMIN_ROUTE,
  GETAGENT_ROUTE,
  GETBUSINESS_ROUTE,
  GETKYB_ROUTE,
  GETTRANSACTION_ROUTE,
  GETUSER_ROUTE,
  GETWALLET_ROUTE,
} from 'routes/path';
import { AppRouteConfig } from 'types/navigation';
import AdminDashboard from 'views/admin/Dashboard';
import GetAgent from 'views/admin/GetAgent';
import GetBusiness from 'views/admin/GetBusiness';
import GetKYB from 'views/admin/GetKYB';
import Transaction from 'views/admin/GetTransactions';
import GetWallets from 'views/admin/GetWallets';
import UserTableData from 'views/admin/UserTable';

const adminRoutes: AppRouteConfig[] = [
  {
    path: ADMIN_ROUTE,
    element: createElement(AdminDashboard),
    restricted: true,
  },
  {
    path: GETWALLET_ROUTE,
    element: createElement(GetWallets),
    restricted: true,
  },
  {
    path: GETUSER_ROUTE,
    element: createElement(UserTableData),
    restricted: true,
  },
  {
    path: GETAGENT_ROUTE,
    element: createElement(GetAgent),
    restricted: true,
  },
  {
    path: GETBUSINESS_ROUTE,
    element: createElement(GetBusiness),
    restricted: true,
  },
  {
    path: GETKYB_ROUTE,
    element: createElement(GetKYB),
    restricted: true,
  },
  {
    path: GETTRANSACTION_ROUTE,
    element: createElement(Transaction),
    restricted: true,
  },
];

export default adminRoutes;
