/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, Table } from 'antd';
import useTableScroll from 'components/useTableScroll';
import { FC } from 'react';

interface AgentDetailsProps {
  isOpen: boolean;
  handleCancel: () => void;
  agent: any | null;
  isLoading: boolean;
}

const AgentDetails: FC<AgentDetailsProps> = ({ isOpen, handleCancel, agent, isLoading }) => {
  const formatNumber = (num: number | string) => {
    const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
    return isNaN(parsedNum) ? '0' : new Intl.NumberFormat().format(parsedNum);
  };
  const scrollX = useTableScroll();

  const agentColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date: string) => (date ? new Date(date).toLocaleString() : 'N/A'),
    },
    {
      title: 'Successful Transactions',
      dataIndex: 'successful_transactions',
      key: 'successful_transactions',
      render: (value: string | number) => formatNumber(value),
    },
    {
      title: 'Failed Transactions',
      dataIndex: 'failed_transactions',
      key: 'failed_transactions',
      render: (value: string | number) => formatNumber(value),
    },
  ];

  const walletsColumns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (value: string | number) => formatNumber(value),
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (value: string | number) => formatNumber(value),
    },
    {
      title: 'Processing',
      dataIndex: 'processing',
      key: 'processing',
      render: (value: string | number) => formatNumber(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  return (
    <Drawer
      open={isOpen}
      closable
      destroyOnClose
      width={700}
      title={<p>Agent Details</p>}
      onClose={handleCancel}
      loading={isLoading}
    >
      {agent ? (
        <div className="space-y-4">
          <h3 className="font-semibold">Agent Information</h3>
          <Table
            columns={agentColumns}
            dataSource={[
              {
                key: agent?.details?.id || '1',
                name: agent?.details?.name || 'N/A',
                email: agent?.details?.email || 'N/A',
                phone_number: agent?.details?.phone_number || 'N/A',
                created_at: agent?.details?.created_at || '',
                successful_transactions: agent?.successful_transactions || 0,
                failed_transactions: agent?.failed_transactions || 0,
              },
            ]}
            pagination={false}
            bordered
            scroll={{
              x: scrollX,
            }}
          />

          <h3 className="font-semibold mt-4">Wallets</h3>
          {agent?.wallets?.length > 0 ? (
            <Table
              columns={walletsColumns}
              dataSource={agent.wallets.map((wallet: any) => ({
                ...wallet,
                key: wallet.id,
              }))}
              pagination={false}
              bordered
              scroll={{
                x: scrollX,
              }}
            />
          ) : (
            <p>No wallets available.</p>
          )}
        </div>
      ) : (
        <p>No details available.</p>
      )}
    </Drawer>
  );
};

export default AgentDetails;
