import ActionButton from 'components/adminComponent/ActionButton';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import Pairs from 'components/adminComponent/Pairs';
import Statistics from 'components/adminComponent/Statistics';

const AdminDashboard = () => {
  return (
    <AdminSideBar>
      <div className="flex flex-col gap-[30px] lg:gap-[60px]">
        <ActionButton />
        <Statistics />
        <Pairs />
      </div>
    </AdminSideBar>
  );
};

export default AdminDashboard;
