import StatBox from 'components/adminComponent/StatBox';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useGetStatsMutation } from 'services/admin';

const Statistics: React.FC = () => {
  const [getStats, { data }] = useGetStatsMutation();

  useEffect(() => {
    getStats({});
  }, [getStats]);

  const statistics = data?.data;
  if (!statistics) {
    return (
      <p className="lg:w-[50%] bg-white rounded-lg shadow-lg p-6 font-bold underline">
        No Statistics data available.
      </p>
    );
  }

  const formatNumber = (num: string | number) => {
    const parsedNum = typeof num === 'string' ? parseFloat(num) : num;
    return isNaN(parsedNum) ? 'N/A' : new Intl.NumberFormat().format(parsedNum);
  };

  return (
    <div className="flex flex-col gap-4 p-4 lg:p-6 w-full bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold">Performance</h1>
      <div className="flex justify-between flex-col lg:flex-row gap-4 lg:h-[200px]">
        <NavLink
          to="/admin/transactions"
          className="flex flex-col justify-center items-center gap-4 p-6 bg-white rounded-lg shadow-lg lg:w-[25%] h-full cursor-pointer"
        >
          <h1 className="">Transaction Volume</h1>
          <div className="flex justify-center">
            <h1 className="font-[800]">{formatNumber(statistics.transactionVolume.total)}</h1>
          </div>
        </NavLink>
        <div className="flex flex-wrap gap-4 lg:w-[75%]">
          <StatBox
            title="Total Fees"
            value={formatNumber(statistics.totalFees.total)}
            link="/admin/transactions"
          />
          <StatBox
            title="Total Clients"
            value={formatNumber(statistics.totalClients)}
            link="/admin/users"
          />
          <StatBox
            title="Total Transactions"
            value={formatNumber(statistics.totalNumberOfTransactions)}
            link="/admin/transactions"
          />
          <StatBox
            title="Verified Clients"
            value={formatNumber(statistics.totalVerifiedClients)}
            link="/admin/users"
          />
          <StatBox
            title="Clients With Transactions"
            value={formatNumber(statistics.totalClientsWithTransactions.userCount)}
            link="/admin/transactions"
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
