export const BASE_ROUTE = '/';
export const REGISTER_ROUTE = '/register';
export const REGISTER_SUCCESS_ROUTE = '/register/success';
export const LOGIN_ROUTE = '/login';
export const KYB_ROUTE = '/account-verification';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const ERROR_404_ROUTE = '/error/404';
export const BENEFICIARIES_ROUTE = '/beneficiaries';
export const ACCOUNT_ROUTE = '/accounts';
export const TRANSACTION_ROUTE = '/transactions';
export const KYC_ROUTE = '/verifyKyc';
export const REQUIRED_ROUTE = '/required/document';
export const DETAIL_ROUTE = '/detail';
export const ADDRESS_ROUTE = '/address';
export const QUESTIONAIRE_ROUTE = '/questionnaire';
export const DOCUMENT_ROUTE = '/documents';
export const INFORMATION_ROUTE = '/information';
export const VERIFICATION_ROUTE = '/verification';
export const AGENT_ROUTE_BASE = '/agent/dashbord';
export const AGENT_WALLET_ROUTE = '/agent/wallet';
export const AGENT_TRANSACTION_ROUTE = '/agent/transaction';
export const AGENT_LOGIN_ROUTE = '/agent/login';
export const AGENT_FORGOT_PASSWORD_ROUTE = '/agent/forgot-password';
export const PAYMENT_ROUTE = '/payment';
export const ADMIN_ROUTE = '/admin/dashboard';
export const ADMIN_LOGIN_ROUTE = '/admin/login';
export const GETWALLET_ROUTE = '/admin/wallets';
export const GETUSER_ROUTE = '/admin/users';
export const GETAGENT_ROUTE = '/admin/agents';
export const GETBUSINESS_ROUTE = '/admin/businesses';
export const GETKYB_ROUTE = '/admin/kyb';
export const GETTRANSACTION_ROUTE = '/admin/transactions';
