/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useGetKYBDocMutation } from 'services/admin';

interface KybDocProps {
  isOpen: boolean;
  handleCancel: () => void;
  kybId: string | null;
}

const KybDoc: FC<KybDocProps> = ({ isOpen, handleCancel, kybId }) => {
  const [getKYBDoc, { isLoading }] = useGetKYBDocMutation();
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    const fetchKYBDocs = async () => {
      if (isOpen && kybId) {
        try {
          const response = await getKYBDoc({ id: kybId }).unwrap();
          setDocuments(response?.data || []);
        } catch (error) {
          //   console.error('Failed to fetch KYB documents:', error);
        }
      }
    };

    fetchKYBDocs();
  }, [isOpen, kybId, getKYBDoc]);

  return (
    <Drawer
      open={isOpen}
      closable
      destroyOnClose
      width={600}
      title={<p>KYB Document</p>}
      onClose={handleCancel}
      loading={isLoading}
    >
      <div className="flex flex-col gap-6 text-[15px]">
        {documents.length > 0 ? (
          documents.map((doc, index) => (
            <div key={index} className="border p-4 rounded-lg shadow-sm">
              <p>
                <strong>Type:</strong> {doc.type}
              </p>
              <p>
                <strong>Status:</strong> {doc.status}
              </p>
              <p>
                <strong>Created At:</strong> {new Date(doc.created_at).toLocaleString()}
              </p>
              <p>
                <strong>Sequence No:</strong> {doc.sequence_no}
              </p>

              {doc.kyb?.business && (
                <div className="mt-2 p-2 bg-gray-100 rounded">
                  <h3 className="font-semibold">Business Information</h3>
                  <p>
                    <strong>Name:</strong> {doc.kyb.business.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {doc.kyb.business.email}
                  </p>
                  <p>
                    <strong>Address:</strong> {doc.kyb.business.address}
                  </p>
                  <p>
                    <strong>Country:</strong> {doc.kyb.business.country}
                  </p>
                  <p>
                    <strong>Status:</strong> {doc.kyb.business.status}
                  </p>
                </div>
              )}

              {doc.kyb?.business?.owner && (
                <div className="mt-2 p-2 bg-gray-100 rounded">
                  <h3 className="font-semibold">Owner Information</h3>
                  <p>
                    <strong>Name:</strong>{' '}
                    {`${doc.kyb.business.owner.first_name} ${doc.kyb.business.owner.last_name}`}
                  </p>
                  <p>
                    <strong>Email:</strong> {doc.kyb.business.owner.email}
                  </p>
                  <p>
                    <strong>Phone:</strong> {doc.kyb.business.owner.phone_number}
                  </p>
                  <p>
                    {' '}
                    <strong>Verification Status:</strong>{' '}
                    {doc.kyb.business.owner.is_kyb_verified ? 'Verified' : 'Not Verified'}{' '}
                  </p>
                </div>
              )}

              <div className="mt-2">
                <a
                  href={doc.document_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  View Document
                </a>
              </div>
            </div>
          ))
        ) : (
          <p>No KYB documents available.</p>
        )}
      </div>
    </Drawer>
  );
};

export default KybDoc;
