/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification, Popconfirm, Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import tagIcon from 'assets/tag.svg';
import InitialRequestModal from 'components/adminComponent/InitialRequest';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useGetUpdateUsersMutation, useGetUsersMutation } from 'services/admin';

type User = {
  id: string;
  name: string;
  user: {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    is_email_verified: boolean;
    is_identity_verified: boolean;
  };
};

type GetUsersResponse = {
  data: User[];
  error?: string;
};

const UserTable = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [getUsers] = useGetUsersMutation<GetUsersResponse>();
  const [searchQuery, setSearchQuery] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [updateUserStatus] = useGetUpdateUsersMutation<{ id: string; status: string }>();
  const scrollX = useTableScroll();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsers({});
        if (response?.data?.data) {
          setUsers(response.data.data);
        }
      } catch (error) {
        notification.error({
          message: `Error fetching users:, ${error}`,
        });
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [getUsers]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleRowClick = (record: User, event: React.MouseEvent) => {
    if (event.target instanceof HTMLElement && event.target.closest('button')) {
      return;
    }
    setSelectedUser(record);
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteSuccess = () => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.id !== selectedUser?.id));
    setIsModalOpen(false);
  };

  const handleUpdate = async (userId: string) => {
    try {
      const updatedUser = await updateUserStatus({ id: userId });

      notification.success({
        message: 'Success',
        description: `User status updated successfully.`,
      });

      if (updatedUser.data && updatedUser.data.status) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, status: updatedUser.data.status } : user
          )
        );
      } else {
        notification.error({
          message: 'No data or status found in the updated user',
        });
      }
    } catch (error) {
      notification.error({
        message: `Error updating user status: ${error}`,
      });
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Email Verified',
      dataIndex: 'is_email_verified',
      key: 'is_email_verified',
      render: (isVerified: boolean) => (isVerified ? 'Yes' : 'No'),
    },
    {
      title: 'Identity Verified',
      dataIndex: 'is_identity_verified',
      key: 'is_identity_verified',
      render: (isVerified: boolean) => (isVerified ? 'Yes' : 'No'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: '',
      dataIndex: 'update',
      render: (_, record) => (
        <Popconfirm
          title="update user status"
          onConfirm={() => handleUpdate(record.id)}
          okText="Yes"
          cancelText="No"
          trigger="hover"
        >
          <button>
            <img src={tagIcon} alt="tag" style={{ cursor: 'pointer' }} />
          </button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
      <div className="flex justify-between items-center border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
        <div className="lg:w-[20%]">
          <h1 className="font-[900] text-[#343434] text-[14px] lg:text-[24px] lg:leading-[40px]">
            Clients Data
          </h1>
        </div>
        <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
          <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Your Accounts
          </button>
          <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Recipients
          </button>
        </div>
        <div className="input flex items-center">
          <img
            src={searchIcon}
            alt={searchIcon}
            className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
          />
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search Clients by Name"
            className="bg-[#F5F5F5] pl-[35px] lg:w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            className="cursor-pointer"
            columns={columns}
            dataSource={filteredUsers}
            rowKey="id"
            pagination={false}
            onRow={(record) => ({
              onClick: (event) => handleRowClick(record, event),
            })}
            scroll={{
              x: scrollX,
            }}
          />
        </div>
      )}
      <InitialRequestModal
        isOpen={isModalOpen}
        handleCancel={handleModalCancel}
        selectedUser={selectedUser}
        onDeleteSuccess={handleDeleteSuccess}
        visibleButtons={['delete']}
      />
    </div>
  );
};

export default UserTable;
