/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import InitialRequestModal from 'components/adminComponent/InitialRequest';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useGetWalletsMutation } from 'services/admin';

type User = {
  id: string;
  name: string;
  user: {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    is_email_verified: boolean;
    is_identity_verified: boolean;
  };
};

type GetUsersResponse = {
  data: User[];
  error?: string;
};

const UserWallet = () => {
  const [loading, setLoading] = useState(true);
  const [wallets, setWallets] = useState<User[]>([]);
  const [getWallets] = useGetWalletsMutation<GetUsersResponse>();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWallets, setFilteredWallets] = useState<any[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const scrollX = useTableScroll();

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleDeleteSuccess = () => {
    setWallets((prevUsers) => prevUsers.filter((user) => user.id !== selectedUser?.id));
    setIsModalOpen(false);
  };

  const handleRowClick = (record: User, event: React.MouseEvent) => {
    if (event.target instanceof HTMLElement && event.target.closest('button')) {
      return;
    }
    setSelectedUser({
      ...record,
      id: record.user.id,
      name: record.user.name,
      email: record.user.email,
      phone_number: record.user.phone_number,
      is_email_verified: record.user.is_email_verified,
      is_identity_verified: record.user.is_identity_verified,
    } as User);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await getWallets({}).unwrap();
        setWallets(response.data);
        setWallets(response.data.data);
        setFilteredWallets(response.data);
      } catch (error) {
        // console.error('Failed to fetch wallets:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchWallets();
  }, [getWallets]);

  useEffect(() => {
    if (searchTerm) {
      const filteredData = wallets.filter((wallet) =>
        wallet.user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredWallets(filteredData);
    } else {
      setFilteredWallets(wallets);
    }
  }, [searchTerm, wallets]);

  const formatAmount = (amount: number) => {
    return amount ? new Intl.NumberFormat().format(amount) : '0';
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => record.user?.name || 'N/A',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (_: any, record: any) => record.user?.phone_number || 'N/A',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_: any, record: any) => record.user?.email || 'N/A',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (balance: number) => formatAmount(balance),
    },
    {
      title: 'Available',
      dataIndex: 'available',
      key: 'available',
      render: (available: number) => formatAmount(available),
    },
  ];

  return (
    <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
      <div className="flex justify-between items-center border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
        <div className="lg:w-[20%]">
          <h1 className="font-[900] text-[#343434] text-[14px] lg:text-[24px] lg:leading-[40px]">
            Clients Wallets
          </h1>
        </div>
        <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
          <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Your Accounts
          </button>
          <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
            Recipients
          </button>
        </div>
        <div className="input flex items-center">
          <img
            src={searchIcon}
            alt="search"
            className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
          />
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Clients by Name"
            className="bg-[#F5F5F5] pl-[35px] lg:w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
          <Spin size="large" />
        </div>
      ) : (
        <Table
          className="cursor-pointer"
          columns={columns}
          dataSource={filteredWallets}
          rowKey="id"
          pagination={false}
          onRow={(record) => ({
            onClick: (event) => handleRowClick(record, event),
          })}
          scroll={{
            x: scrollX,
          }}
        />
      )}
      <InitialRequestModal
        isOpen={isModalOpen}
        handleCancel={handleModalCancel}
        selectedUser={selectedUser}
        onDeleteSuccess={handleDeleteSuccess}
        visibleButtons={['fund', 'generate']}
      />
    </div>
  );
};

export default UserWallet;
