import { Drawer } from 'antd';
import BusinessIcon from 'assets/Benefit';
import logoIcon from 'assets/brand_logo.svg';
import HomeIcon from 'assets/HomeIcon';
import AgentIcon from 'assets/Profile';
import TransIcon from 'assets/Transaction';
import AccountIcon from 'assets/Wallet';
import { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import 'styles/dashboard.css';

interface NavItemProps {
  to: string | string[];
  icon: React.ReactNode;
  label: string;
}

const NavItem: FC<NavItemProps> = ({ to, icon, label }) => {
  const { pathname } = useLocation();
  const isActive = Array.isArray(to) ? to.includes(pathname) : pathname === to;

  return (
    <>
      {isActive ? (
        <div className="das text-[#1174ED] font-[700] flex justify-between items-center w-[100%]">
          <div className="flex items-center gap-[10px]">
            {icon}
            <p>{label}</p>
          </div>
        </div>
      ) : (
        <NavLink to={Array.isArray(to) ? to[0] : to}>
          <div className="flex items-center gap-[10px] cursor-pointer text-[#09090A] font-[400] text-[16px] leading-[22.4px] h-[36px]">
            {icon}
            <p>{label}</p>
          </div>
        </NavLink>
      )}
    </>
  );
};

interface MobileSideBarProps {
  isOpen: boolean;
  handleCancel: () => void;
}

const MobileSideBar: FC<MobileSideBarProps> = ({ isOpen, handleCancel }) => {
  const sidebarItems = useMemo(
    () => [
      { to: '/admin/dashboard', icon: <HomeIcon />, label: 'Dashboard' },
      { to: '/admin/users', icon: <AgentIcon />, label: 'Clients' },
      { to: '/admin/wallets', icon: <AccountIcon />, label: 'Wallet' },
      { to: '/admin/agents', icon: <AgentIcon />, label: 'Agents' },
      { to: '/admin/businesses', icon: <BusinessIcon />, label: 'Business' },
      { to: '/admin/kyb', icon: <TransIcon />, label: 'KYB' },
      { to: '/admin/transactions', icon: <TransIcon />, label: 'Transactions' },
    ],

    []
  );

  useEffect(() => {
    const selectedTab = sidebarItems.find((item) =>
      Array.isArray(item.to)
        ? item.to.includes(window.location.pathname)
        : item.to === window.location.pathname
    );

    if (selectedTab) {
      document.title = `AstraBiz | ${selectedTab.label}`;
    }
  }, [sidebarItems]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
    }
  }, [isOpen]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<img src={logoIcon} alt={logoIcon} className="h-[63px]" />}
      open={isOpen}
      loading={loading}
      onClose={handleCancel}
      width={250}
      placement="left"
    >
      <div className="flex flex-col gap-4 items-cente text-[#09090A] text-[16px] not-italic font-[400] leading-[22.4px] w-[100%] pl-[10px]">
        {sidebarItems.map((item, index) => (
          <NavItem key={index} to={item.to} icon={item.icon} label={item.label} />
        ))}
      </div>
    </Drawer>
  );
};

export default MobileSideBar;
