import AdminSideBar from 'components/adminComponent/AdminSideBar';
import UserTable from 'components/adminComponent/UserTable';

const UserTableData = () => {
  return (
    <AdminSideBar>
      <UserTable />
    </AdminSideBar>
  );
};

export default UserTableData;
