/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import AgentDetails from 'components/adminComponent/AgentDetailModal';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useGetAgentMutation, useGetAgentStatsMutation } from 'services/admin';

const GetAgent = () => {
  const [getAgent, { isLoading }] = useGetAgentMutation();
  const [getAgentStats, { isLoading: isFetchingStats }] = useGetAgentStatsMutation();
  const [agents, setAgents] = useState<any[]>([]);
  const [filteredAgents, setFilteredAgents] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<any | null>(null);
  const scrollX = useTableScroll();

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await getAgent({}).unwrap();
        setAgents(response.data);
        setFilteredAgents(response.data);
      } catch (error) {
        // console.error('Error fetching agents:', error);
      }
    };

    fetchAgents();
  }, [getAgent]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = agents.filter((agent) => agent.name.toLowerCase().includes(value));
    setFilteredAgents(filtered);
  };

  const handleRowClick = async (agentId: string) => {
    setIsModalOpen(true);
    try {
      const statsResponse = await getAgentStats(agentId).unwrap();
      setSelectedAgent(statsResponse.data);
    } catch (error) {
      // console.error('Error fetching agent details:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedAgent(null);
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
  ];

  return (
    <AdminSideBar>
      <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
        <div className="flex justify-between items-center border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
          <div className="lg:w-[20%]">
            <h1 className="font-[900] text-[#343434] text-[14px] lg:text-[24px] lg:leading-[40px]">
              Agents
            </h1>
          </div>
          <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
            <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
              Your Accounts
            </button>
            <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
              Recipients
            </button>
          </div>
          <div className="input flex items-center">
            <img
              src={searchIcon}
              alt="search"
              className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
            />
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search Agent by Name"
              className="bg-[#F5F5F5] pl-[35px] lg:w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center min-h-[150px]">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            className="cursor-pointer"
            columns={columns}
            dataSource={filteredAgents}
            rowKey="id"
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
            })}
            scroll={{
              x: scrollX,
            }}
          />
        )}
      </div>

      <AgentDetails
        isOpen={isModalOpen}
        handleCancel={closeModal}
        agent={selectedAgent}
        isLoading={isFetchingStats}
      />
    </AdminSideBar>
  );
};

export default GetAgent;
