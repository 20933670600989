import { Button, Modal, notification } from 'antd';
import { FC } from 'react';
import { useDeleteUsersMutation } from 'services/admin';

type User = {
  name: string;
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    is_email_verified: boolean;
    is_identity_verified: boolean;
  };
};

interface DeletetModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  selectedUser?: User | null;
  onDeleteSuccess: () => void;
}

const DeletetModal: FC<DeletetModalProps> = ({
  isOpen,
  handleCancel,
  selectedUser,
  onDeleteSuccess,
}) => {
  const [deleteUsers, { isLoading, error }] = useDeleteUsersMutation();

  const handleDelete = async () => {
    if (!selectedUser) return;

    try {
      await deleteUsers({ id: selectedUser.id });

      if (!error) {
        notification.success({
          message: 'User Deleted',
          description: `User ${selectedUser.name} has been deleted successfully.`,
        });
        onDeleteSuccess();
      } else {
        notification.error({
          message: 'Error Deleting User',
          description: `There was an error deleting the user.`,
        });
      }
    } catch (err) {
      notification.error({
        message: 'Error Deleting User',
        description: `There was an error deleting the user.`,
      });
    } finally {
      handleCancel();
    }
  };

  return (
    <Modal open={isOpen} onCancel={handleCancel} onOk={handleDelete} confirmLoading={isLoading}>
      <div className="flex flex-col gap-6 text-[15px]">
        <div>
          <p>
            Are you sure you want to delete user{' '}
            <strong>{selectedUser?.name || 'this user'}</strong>?
          </p>
        </div>
        <div className="flex justify-end">
          <div className="flex gap-2 items-center">
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleDelete} loading={isLoading}>
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeletetModal;
