/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, notification, Select } from 'antd';
import rightIcon from 'assets/right _arrow.svg';
import { useFormik } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { usePostRateMutation } from 'services/admin';
import * as Yup from 'yup';

const { Option } = Select;

interface AddRateProps {
  open: boolean;
  onClose: () => void;
}

const AddRate: FC<AddRateProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [postRate] = usePostRateMutation();

  const validationSchema = Yup.object({
    from: Yup.string()
      .oneOf(['NGN', 'CNY', 'USD', 'HKD'], 'From currency must be one of NGN, CNY, USD, HKD')
      .required('From currency is required'),
    to: Yup.string()
      .oneOf(['NGN', 'CNY', 'USD', 'HKD'], 'To currency must be one of NGN, CNY, USD, HKD')
      .required('To currency is required'),
    rate: Yup.number()
      .typeError('Rate must be a number')
      .required('Rate is required')
      .positive('Rate must be a positive number'),
  });

  const formik = useFormik({
    initialValues: {
      from: '',
      to: '',
      rate: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const parsedValues = {
        ...values,
        rate: Number(values.rate),
      };

      try {
        const response = await postRate(parsedValues).unwrap();

        if (response.status === 200) {
          notification.success({
            message: 'Rate Added',
            description: 'The exchange rate has been added successfully.',
          });
          resetForm();
          onClose();
        } else {
          notification.error({
            message: 'Error Adding Rate',
            description: response.message || 'There was an error adding the exchange rate.',
          });
        }
      } catch (error: any) {
        notification.error({
          message: 'Error Adding Rate',
          description: error.data.message || 'There was an error adding the exchange rate.',
        });
      }
    },
  });

  const formikRef = useRef(formik);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
      formikRef.current.resetForm();
    }
  }, [open]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Add Rate</p>}
      open={open}
      loading={loading}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      width={500}
    >
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1">
          <p>From</p>
          <Select
            value={formik.values.from}
            onChange={(value) => formik.setFieldValue('from', value)}
            onBlur={() => formik.setFieldTouched('from', true)}
          >
            <Option value="">Select Currency</Option>
            <Option value="NGN">NGN</Option>
            <Option value="CNY">CNY</Option>
            <Option value="USD">USD</Option>
            <Option value="HKD">HKD</Option>
          </Select>
          {formik.touched.from && formik.errors.from ? (
            <div className="text-red-500">{formik.errors.from}</div>
          ) : null}
        </div>
        <div className="flex flex-col gap-1">
          <p>To</p>
          <Select
            value={formik.values.to}
            onChange={(value) => formik.setFieldValue('to', value)}
            onBlur={() => formik.setFieldTouched('to', true)}
          >
            <Option value="">Select Currency</Option>
            <Option value="NGN">NGN</Option>
            <Option value="CNY">CNY</Option>
            <Option value="USD">USD</Option>
            <Option value="HKD">HKD</Option>
          </Select>
          {formik.touched.to && formik.errors.to ? (
            <div className="text-red-500">{formik.errors.to}</div>
          ) : null}
        </div>
        <div className="flex flex-col gap-1">
          <p>Rate</p>
          <input
            name="rate"
            placeholder="Enter rate"
            className="pl-4 focus:outline-none border border-[#D0D0D0] rounded-[12px] h-[54px]"
            value={formik.values.rate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.rate && formik.errors.rate ? (
            <div className="text-red-500">{formik.errors.rate}</div>
          ) : null}
        </div>
        <button
          type="submit"
          className="flex justify-center items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg mt-4"
        >
          Send Request
          <img src={rightIcon} alt="right icon" />
        </button>
      </form>
    </Drawer>
  );
};

export default AddRate;
