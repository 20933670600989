/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification, Popconfirm, Select, Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import tagIcon from 'assets/tag.svg';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import ApproveWithdrawalModal from 'components/adminComponent/ApproveWithdrawalModal';
import useTableScroll from 'components/useTableScroll';
import { useCallback, useEffect, useState } from 'react';
import {
  useApproveTransactionMutation,
  useAssignTransactionMutation,
  useGetAgentMutation,
  useGetTransactionMutation,
} from 'services/admin';

const { Option } = Select;

const Transaction = () => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<any[]>([]);
  const [getTransaction] = useGetTransactionMutation();
  const [approveWithdrawal] = useApproveTransactionMutation();
  const [getAgent] = useGetAgentMutation();
  const [assignTransaction] = useAssignTransactionMutation();
  const [agents, setAgents] = useState<any[]>([]);
  const [agentsLoading, setAgentsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedTransactionId, setSelectedTransactionId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const scrollX = useTableScroll();

  const fetchTransactions = async (status = '') => {
    try {
      const response = await getTransaction(status).unwrap();
      setTransactions(response.data);
      setLoading(false);
    } catch (error: unknown) {
      notification.error({
        message: 'Error Fetching Transactions',
        description: (error as any)?.data?.message || 'There was an error fetching transactions.',
      });
      setLoading(false);
    }
  };

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
    setLoading(true);
    fetchTransactions(value);
  };

  const fetchAgents = useCallback(async () => {
    try {
      const response = await getAgent({}).unwrap();
      setAgents(response.data);
      setAgentsLoading(false);
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'data' in error) {
        notification.error({
          message: 'Error Fetching Agents',
          description: (error as any).data.message || 'There was an error fetching agents.',
        });
      } else {
        notification.error({
          message: 'Error Fetching Agents',
          description: 'There was an error fetching agents.',
        });
      }
      setAgentsLoading(false);
    }
  }, [getAgent]);

  useEffect(() => {
    fetchTransactions();
    fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = (transaction: any) => {
    if (transaction.type === 'WITHDRAW') {
      setSelectedTransactionId(transaction.id);
      setModalVisible(true);
    } else {
      notification.warning({
        message: 'Action Restricted',
        description: 'You can only approve transactions with the WITHDRAW role.',
      });
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedTransactionId('');
    fetchTransactions();
  };

  const handleApprove = async (id: string) => {
    try {
      await approveWithdrawal({ id }).unwrap();
      notification.success({
        message: 'Transaction Approved',
        description: 'The transaction has been approved successfully.',
      });
      fetchTransactions();
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'data' in error) {
        notification.error({
          message: 'Error Approving Transaction',
          description:
            (error as any).data.message || 'There was an error approving the transaction.',
        });
      } else {
        notification.error({
          message: 'Error Approving Transaction',
          description: 'There was an error approving the transaction.',
        });
      }
    }
  };

  const handleAssignAgent = async (transactionId: string, agentId: string) => {
    try {
      await assignTransaction({ transaction_id: transactionId, agent_id: agentId }).unwrap();
      notification.success({
        message: 'Agent Assigned',
        description: 'The agent has been assigned to the transaction successfully.',
      });
      fetchTransactions();
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'data' in error) {
        notification.error({
          message: 'Error Assigning Agent',
          description: (error as any).data.message || 'There was an error assigning the agent.',
        });
      } else {
        notification.error({
          message: 'Error Assigning Agent',
          description: 'There was an error assigning the agent.',
        });
      }
    }
  };

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(amount);
  };

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'Agent',
      dataIndex: 'agent',
      key: 'agent',
      render: (_, record) => (record.agent ? record.agent.name : 'N/A'),
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => `${formatAmount(record.amount.value)} ${record.amount.currency}`,
    },
    {
      title: 'Payment Info',
      dataIndex: 'payment_info',
      key: 'payment_info',
      render: (_, record) => `${record.payment_info.provider}`,
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      key: 'purpose',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: string) =>
        new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        }).format(new Date(text)),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Assign Agent',
      dataIndex: 'assign',
      render: (_, record) => (
        <button className="selectInput" onClick={(e) => e.stopPropagation()}>
          <Select
            placeholder="Select Agent"
            style={{ width: 150 }}
            onChange={(value) => handleAssignAgent(record.id, value)}
            loading={agentsLoading}
          >
            {agents &&
              agents.map((agent: any) => (
                <Option key={agent.id} value={agent.id}>
                  {agent.name}
                </Option>
              ))}
          </Select>
        </button>
      ),
    },
    {
      title: '',
      dataIndex: 'update',
      render: (_, record) => (
        <Popconfirm
          title="Approve transaction"
          onConfirm={() => handleApprove(record.id)}
          okText="Yes"
          cancelText="No"
          trigger="hover"
        >
          <button onClick={(e) => e.stopPropagation()}>
            <img src={tagIcon} alt="tag" style={{ cursor: 'pointer' }} />
          </button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <AdminSideBar>
      <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
        <div className="flex justify-between border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
          <div className="w-[20%]">
            <h1 className="font-[900] text-[#343434] text-[18px] lg:text-[24px] leading-[40px]">
              Transactions
            </h1>
          </div>
          <div className="flex items-center gap-6">
            <div className="input md:flex items-center hidden">
              <img
                src={searchIcon}
                alt={searchIcon}
                className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
              />
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search by Transaction Type"
                className="bg-[#F5F5F5] pl-[35px] w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
              />
            </div>
            <div>
              <Select
                value={selectedStatus}
                style={{ width: 200, height: 40 }}
                onChange={handleStatusChange}
                options={[
                  { value: '', label: 'All Transactions' },
                  { value: 'Initiated', label: 'Initiated' },
                  { value: 'Processing', label: 'Processing' },
                  { value: 'Failed', label: 'Failed' },
                  { value: 'Completed', label: 'Completed' },
                ]}
              />
            </div>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center" style={{ minHeight: '150px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <Table
              className="cursor-pointer"
              columns={columns}
              dataSource={filteredTransactions}
              rowKey="id"
              pagination={false}
              onRow={(record) => ({
                onClick: () => handleOpenModal(record),
              })}
              scroll={{
                x: scrollX,
              }}
            />
          </div>
        )}
      </div>

      <ApproveWithdrawalModal
        isOpen={modalVisible}
        onClose={handleModalClose}
        transactionId={selectedTransactionId}
      />
    </AdminSideBar>
  );
};

export default Transaction;
