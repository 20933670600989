import { Modal, notification } from 'antd';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useFundUserMutation } from 'services/admin';
import * as Yup from 'yup';

type User = {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    phone_number: string;
    is_email_verified: boolean;
    is_identity_verified: boolean;
  };
};

interface FundUserModalProps {
  isOpen: boolean;
  handleCancel: () => void;
  selectedUser?: User | null;
}

const FundUserModal: FC<FundUserModalProps> = ({ isOpen, handleCancel, selectedUser }) => {
  const [fundUser, { error }] = useFundUserMutation();

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Amount is required')
      .positive('Amount must be a positive number')
      .integer('Amount must be an integer'),
  });

  const formik = useFormik({
    initialValues: {
      amount: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!selectedUser) return;

      try {
        await fundUser({
          id: selectedUser.id,
          user: selectedUser.id,
          amount: parseInt(values.amount, 10),
        });

        if (!error) {
          notification.success({
            message: 'User Funded',
            description: `User ${selectedUser?.user?.name} wallet has been updated`,
          });
        } else {
          notification.error({
            message: 'Error Funding User',
            description: `There was an error funding the user.`,
          });
        }
      } catch (err) {
        notification.error({
          message: 'Error Funding User',
          description: `There was an error funding the user.`,
        });
      } finally {
        resetForm();
        handleCancel();
      }
    },
  });

  return (
    <Modal open={isOpen} onCancel={handleCancel}>
      <div className="flex flex-col gap-6 text-[15px]">
        <div>
          <p>
            Are you sure you want to fund user{' '}
            <strong>{selectedUser?.user?.name || 'this user'}</strong>?
          </p>
        </div>
        <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
          <input
            name="amount"
            placeholder="Enter Amount"
            className="pl-4 focus:outline-none border border-[#D0D0D0] rounded-[12px] h-[54px] w-full"
            value={formik.values.amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.amount && formik.errors.amount ? (
            <div className="text-red-500 font-normal text-[18px] relative bottom-3 -mb-3">
              {formik.errors.amount}
            </div>
          ) : null}
          <div className="flex justify-end">
            <div className="flex gap-2 items-center">
              <button
                onClick={handleCancel}
                className="flex items-center gap-2 border border-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="border border-[#1174ED] py-[7px] px-[10px] md:px-[20px] rounded-[10px]"
              >
                Continue
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FundUserModal;
