import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const StatBox: React.FC<{ title: string; value: string | number; link: string }> = ({
  title,
  value,
  link,
}) => (
  <NavLink
    to={link}
    className="lg:w-[30%] p-4 bg-white rounded-lg shadow-lg flex flex-col gap-1 justify-center items-center cursor-pointer"
  >
    <h1 className="font-[400]">{title}</h1>
    <p className="font-[900]">{value}</p>
  </NavLink>
);

StatBox.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default StatBox;
