import AdminSideBar from 'components/adminComponent/AdminSideBar';
import UserWallet from 'components/adminComponent/UserWallet';

const GetWallets = () => {
  return (
    <AdminSideBar>
      <UserWallet />
    </AdminSideBar>
  );
};

export default GetWallets;
