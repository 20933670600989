/* eslint-disable @typescript-eslint/no-explicit-any */
import { Drawer, notification } from 'antd';
import rightIcon from 'assets/right _arrow.svg';
import { useFormik } from 'formik';
import { FC, useEffect, useRef, useState } from 'react';
import { useOnboardAgentMutation } from 'services/admin';
import { OnboardAgentInput } from 'types/admin';
import * as Yup from 'yup';

interface AddAgentProps {
  open: boolean;
  onClose: () => void;
}

const AddAgent: FC<AddAgentProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [onboardAgent] = useOnboardAgentMutation();

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    name: Yup.string().required('Name is required'),
    phone_number: Yup.string()
      .required('Phone number is required')
      .matches(/^\+?[1-9]\d{1,14}$/, 'Phone number must include country code and be valid'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      phone_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const parsedValues: OnboardAgentInput = {
        ...values,
        phone_number: values.phone_number,
      };

      try {
        const response = await onboardAgent(parsedValues).unwrap();

        if (response.status === 201) {
          notification.success({
            message: 'Agent Onboarded',
            description: 'The agent has been onboarded successfully.',
          });
          resetForm();
          onClose();
        } else {
          notification.error({
            message: 'Error Onboarding Agent',
            description: response.message || 'There was an error onboarding the agent.',
          });
        }
      } catch (error: any) {
        notification.error({
          message: 'Error Onboarding Agent',
          description: error.data.message || 'There was an error onboarding the agent.',
        });
      }
    },
  });

  const formikRef = useRef(formik);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setLoading(true);
      formikRef.current.resetForm();
    }
  }, [open]);

  return (
    <Drawer
      closable
      destroyOnClose
      title={<p>Add Agent Details</p>}
      open={open}
      loading={loading}
      onClose={() => {
        formik.resetForm();
        onClose();
      }}
      width={500}
    >
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="email"
            placeholder="Enter Email"
            className="flex-1 px-[20px] focus:outline-none"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="name"
            placeholder="Enter Name"
            className="flex-1 px-[20px] focus:outline-none"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="text-red-500">{formik.errors.name}</div>
          ) : null}
        </div>
        <div className="flex items-center border border-[#D0D0D0] rounded-[12px] h-[54px] pr-4">
          <input
            type="text"
            name="phone_number"
            placeholder="Enter Phone Number (e.g., +234...)"
            className="flex-1 px-[20px] focus:outline-none"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.phone_number && formik.errors.phone_number ? (
            <div className="text-red-500">{formik.errors.phone_number}</div>
          ) : null}
        </div>
        <button
          type="submit"
          className="flex justify-center items-center gap-2 text-[#F9F9FB] px-[20px] h-[40px] bg-[#1174ED] rounded-lg mt-4"
        >
          Send Request
          <img src={rightIcon} alt="right icon" />
        </button>
      </form>
    </Drawer>
  );
};

export default AddAgent;
