/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin, Table, TableColumnsType } from 'antd';
import searchIcon from 'assets/search.svg';
import AdminSideBar from 'components/adminComponent/AdminSideBar';
import useTableScroll from 'components/useTableScroll';
import { useEffect, useState } from 'react';
import { useGetBusinessMutation } from 'services/admin';

const GetBusiness = () => {
  const [getBusiness, { isLoading }] = useGetBusinessMutation();
  const [businessData, setBusinessData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const scrollX = useTableScroll();

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const response = await getBusiness({}).unwrap();
        setBusinessData(response?.data || []);
      } catch (error) {
        // console.error('Failed to fetch businesses', error);
      }
    };
    fetchBusinesses();
  }, [getBusiness]);

  const filteredBusinesses = businessData.filter((business) => {
    const ownerName = business.owner?.name?.toLowerCase() || '';
    const businessName = business.name?.toLowerCase() || '';
    return (
      ownerName.includes(searchText.toLowerCase()) ||
      businessName.includes(searchText.toLowerCase())
    );
  });

  const columns: TableColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'owner',
      key: 'owner_name',
      render: (owner) => owner?.name || 'N/A',
    },
    {
      title: 'Business Name',
      dataIndex: 'name',
      key: 'business_name',
    },
    {
      title: 'Business ID',
      dataIndex: 'business_identification_number',
      key: 'business_identification_number',
    },
    {
      title: 'Director',
      dataIndex: 'director',
      key: 'director',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'KYB Verified',
      dataIndex: 'is_kyb_verified',
      key: 'is_kyb_verified',
      render: (text) => (text ? 'Yes' : 'No'),
    },
  ];

  return (
    <AdminSideBar>
      <div className="flex flex-col gap-3 border-[0.5px] border-[#EAECF0] rounded-[10px]">
        <div className="flex justify-between items-center border-b-[0.5px] border-b-[#EAECF0] pb-[15px] pt-[15px] px-[15px] w-[100%]">
          <div className="lg:w-[20%]">
            <h1 className="font-[900] text-[#343434] text-[14px] lg:text-[24px] lg:leading-[40px]">
              Businesses
            </h1>
          </div>
          <div className="lg:flex hidden gap-4 font-[700] text-[12.57px] leading-[18.86px] text-[#8894EC]">
            <button className="flex justify-center items-center border border-[#8894EC] rounded-[30px] h-[33px] py-[7px] px-[20px]">
              Your Accounts
            </button>
            <button className="flex justify-center items-center border border-[#8894EC] bg-[#8894EC33] rounded-[30px] h-[33px] py-[7px] px-[20px]">
              Recipients
            </button>
          </div>
          <div className="input flex items-center">
            <img
              src={searchIcon}
              alt="search"
              className="relative left-[30px] lg:left-[30px] lg:-ml-[30px]"
            />
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search by Name or Business Name"
              className="bg-[#F5F5F5] pl-[35px] lg:w-[300px] rounded-[10px] h-[40px] py-[16.21px]"
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center min-h-[150px]">
            <Spin size="large" />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredBusinesses}
            rowKey="id"
            pagination={false}
            scroll={{
              x: scrollX,
            }}
          />
        )}
      </div>
    </AdminSideBar>
  );
};

export default GetBusiness;
