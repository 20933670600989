import { useEffect, useState } from 'react';

const useTableScroll = () => {
  const [scrollX, setScrollX] = useState<string>('max-content');

  useEffect(() => {
    const handleResize = () => {
      setScrollX('max-content');
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return scrollX;
};

export default useTableScroll;
